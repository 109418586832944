import * as React from "react"
import {Button, Container } from '@mui/material';
import { Link } from "gatsby";



const MainPage = () => {
    return(
      <Container>
        <h1>Payment succeeded!</h1>
      </Container>
    )
  }

  const onCheckout = () => {
    return (
      <main>
        <MainPage></MainPage>
      </main>
    )
  }
  
  
  export default onCheckout;